.careers-box {
    &__icon {
        padding: 15px;
        background: #2b3a6f;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.03);
        border-radius: 15px;
        width: 70px;
        height: 70px;
    }
}

.careers {
    &.card-box {
        padding: 25px;
        position: relative;

        @include max-w-sm {
            max-width: none;
        }

        & .card-box__hover {
            position: absolute;
            top: 0;
            visibility: hidden;
            padding: 25px;
            font-size: 14px;
            line-height: 160%;
            color: #ffffff;

            @include min-w-sm {
                visibility: visible !important;
                opacity: 0;
                transition: opacity 0.2s ease-out;
                -webkit-transition: all 0.2s ease-out;
                -moz-transition: all 0.2s ease-out;
                -o-transition: all 0.2s ease-out;
            }
        }

        & .card-box__static {
            visibility: visible;

            @include min-w-sm {
                visibility: visible !important;
                opacity: 1;
                transition: opacity 0.2s ease-out;
                -webkit-transition: all 0.2s ease-out;
                -moz-transition: all 0.2s ease-out;
                -o-transition: all 0.2s ease-out;
            }
        }

        &:hover {
            & .card-box__static {
                @include min-w-sm {
                    opacity: 0 !important;
                    visibility: visible !important;
                }

                visibility: hidden;
            }

            & .card-box__hover {
                visibility: visible;

                @include min-w-sm {
                    visibility: visible !important;
                    opacity: 1 !important;
                }
            }
        }

        // &:active {
        //     -webkit-transition: opacity 0.2s ease-out;
        // }
    }

    &__rating {
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 20px;
        padding: 40px;

        @include max-w-2xs {
            padding: 20px;
        }
    }

    &__rating-number {
        font-weight: 600;
        font-size: 28px;
        line-height: 130%;
        background: linear-gradient(59.98deg, #25cdaf 18.75%, #11b99b 95.03%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        display: flex;
        align-items: center;
        color: #11b99b;

        & > div {
            & > :not(:last-child) {
                margin-right: 10px;

                @include max-w-2xs {
                    margin-right: 0;
                }
            }

            & > img {
                width: 32px;
                height: 32px;
            }
        }
    }
}

.rating-image {
    & > img {
        border-radius: 50%;
        width: 80px;
        height: 80px;
        object-fit: cover;
        background: linear-gradient(180deg, #851ee9 0%, #b370f3 100%);
    }
}

.values {
    &__container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(224px, 1fr));
        grid-template-rows: repeat(2, 1fr);
        gap: 20px;
        justify-items: center;
    }
}

.reference {
    @include max-w-md {
        & > :not(:first-child) {
            margin-top: 26px;
        }
    }
}

.reason {
    &__image {
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;

        @include max-w-md {
            left: 0;
            transform: translateX(0);
            margin-left: -20px;
        }

        & img {
            max-width: none;
        }
    }

    &__box {
        padding: 25px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 20px;
        max-width: 276px;
        height: max-content;
        background: $white-100;
        transition: opacity 0.5s;

        // & .video-container {
        //     visibility: hidden;
        //     height: 0;
        // }

        &--primary {
            @include max-w-sm {
                opacity: 0;
                top: 50px;
                margin: 0;

                &.active {
                    opacity: 1;
                    z-index: 200;
                }
            }
        }

        &--second {
            @include max-w-sm {
                opacity: 0;
                position: absolute;
                top: 146px;
                margin-top: 0;

                &.active {
                    opacity: 1;
                    z-index: 200;
                }
            }
        }

        &--third {
            @include max-w-sm {
                opacity: 0;
                position: absolute;
                top: 275px;
                margin-top: 0;

                &.active {
                    opacity: 1;
                }
            }
        }

        @include max-w-sm {
            max-width: 257px;
        }

        & .check-list {
            & ul {
                & > li {
                    font-size: 14px;
                }
            }
        }

        & > p {
            font-weight: 600;
            font-size: 18px;
            line-height: 130%;
            color: #3f2d54;
        }
    }
}

.candidate {
    &__container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, max-content);
        gap: 32px;

        @include max-w-md {
            grid-template-columns: auto;
        }

        @include max-w-sm {
            margin-top: 50px;
        }
    }
}

.btm-text-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    color: #3f2d54;
}

.benefits {
    &__icon {
        padding: 15px;
        background: #ffffff;
        box-shadow: 0 5px 10px rgba(125, 20, 236, 0.05);
        border-radius: 15px;
        width: 70px;
        height: 70px;
    }

    &.card-box {
        justify-content: flex-start;
        align-items: flex-start;
        // min-height: 170px;
        width: 100%;
    }

    & > div {
        & > p {
            @include max-w-sm {
                font-size: 15px;
                line-height: 160%;
            }
        }
    }

    &__inner {
        & > div {
            width: 30%;
        }

        @include max-w-sm {
            & > div {
                width: 50%;
            }

            flex-direction: column;
            align-items: center;
        }

        @include max-w-xs {
            & > div {
                width: 100%;
            }
        }
    }
}
