.box-section {
    background: $bg-dark-blue-100;
    border-radius: 20px;
    width: 95.83%;
    max-width: 1380px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;

    & .container {
        @include max-w-sm {
            padding-left: 0;
            padding-right: 0;
        }
    }

    @include max-w-sm {
        padding-left: 30px;
        padding-right: 30px;
    }

    & .box-container {
        width: 72%;
        max-width: 994px;

        @include max-w-xl {
            width: 93%;
            max-width: none;
        }

        @include max-w-sm {
            width: 100%;
        }
    }

    & .card-container {
        width: 256px;
        height: 250px;
    }
}

.card-box {
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(125, 63, 255, 0.1);
    border-radius: 20px;

    &--bright-purple {
        background: $bright-purple-100;
    }

    &--turquoise-10 {
        background: $turquoise-10;
    }

    &--bright-blue {
        background: $bright-blue-100;
    }

    &--white-10 {
        background: $white-10;
    }

    &__inner {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 20px;

        &--radial-gradient {
            background: radial-gradient(
                50% 50% at 50% 50%,
                rgba(255, 255, 255, 0.1) 0%,
                rgba(255, 255, 255, 0.05) 100%
            );
        }

        &--radial-gradient-1 {
            background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.4) 100%);
        }

        &--turquoise-10 {
            background: $turquoise-10;
        }

        &--bright-purple {
            background: $bright-purple-100;
        }

        &--bright-blue {
            background: $bright-blue-100;
        }

        &--green {
            background: rgba(162, 229, 121, 0.1);
        }
    }
}

.overview-card {
    width: 290px;
}

.plus-box-container {
    display: flex;
    align-items: center;
    height: 250px;

    @include max-w-sm {
        height: 100%;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    & .plus-box {
        background: #ffffff;
        box-shadow: $shadow-lvl-1;
        border-radius: 15px;
        padding: 11px;
    }
}

.product-solution {
    row-gap: 40px;

    @include max-w-xl {
        justify-content: center;
        column-gap: 40px;
    }

    & .card-container {
        width: 310px;
        height: 250px;

        @include max-w-xs {
            width: 295px;
        }

        @include max-w-2xs {
            width: 260px;
        }
    }
}

.overview {
    row-gap: 40px;
    column-gap: 10px;

    & > :nth-child(1) {
        & .card-box {
            background: rgba(125, 63, 255, 0.1);

            &__inner {
                & img {
                    width: 100px;
                    object-fit: contain;
                }
            }
        }
    }

    & > :nth-child(3) {
        & .card-box {
            background: rgba(37, 205, 175, 0.1);

            &__inner {
                & img {
                    width: 100px;
                    object-fit: contain;
                }
            }
        }
    }

    & > :nth-child(5) {
        & .card-box {
            background: rgba(77, 84, 255, 0.1);

            &__inner {
                & img {
                    width: 140px;
                    object-fit: contain;
                }
            }
        }
    }

    @include max-w-sm {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 0;
    }
}

.problem-box.card-box {
    height: auto;
}
