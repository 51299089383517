.faq {
    &__content {
        @include min-w-md {
            display: flex;
        }
    }

    &__list {
        @include max-w-sm {
            &:nth-child(n + 2) {
                margin-top: 40px;
            }
        }

        flex: 1 1 50%;

        & > li:nth-child(n + 2) {
            @include min-w-sm {
                margin-top: 30px;
            }

            margin-top: 20px;
        }
        // display: grid;
        // grid-auto-columns: auto;
        // grid-column-gap: 0;
        // grid-row-gap: 2em;
        // grid-template-columns: repeat(2, 0.5fr);
        // grid-template-rows: auto;
        // break-inside: avoid-column;
        // column-gap: 3em;
        // column-count: 2;
    }

    &__item {
        padding: 30px;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.03);
        border-radius: 12px;

        @include max-w-xs {
            padding: 20px;
        }

        &.is-open {
            & .faq__headline {
                font-weight: 600;
            }
        }
    }

    & .faq__head {
        & .accordion__toggler {
            margin-top: 7px;
        }
    }

    &__headline {
        @include max-w-xs {
            font-weight: 600;
            font-size: 16px;
            line-height: 140%;
        }

        font-weight: 600;
        font-size: 18px;
        line-height: 130%;
        color: $text-black-100;
        transition: font-weight 0.2s, color 0.3s;
    }

    &__holder {
        padding-top: 20px;

        @include max-w-xs {
            font-weight: 400;
            font-size: 14px;
            line-height: 160%;
        }

        & p {
            font-weight: 400;
            font-size: 16px;
            line-height: 160%;
            color: $gray-100;
        }

        & ul {
            margin-top: 5px;

            & > li {
                margin-top: 10px;
                font-weight: 400;
                font-size: 16px;
                line-height: 160%;
                color: $gray-100;

                & > span {
                    font-weight: 600;
                    color: $text-black-100;
                }

                &::before {
                    content: "";
                    display: inline-block;
                    position: relative;
                    width: 15px;
                    height: 15px;
                    top: 2px;
                    border-radius: 50%;
                    background: #f2e7fd;
                    background-image: url("/images/svg/check-icon.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    margin-right: 5.5px;
                }
            }
        }
    }
}

.faq-container {
    width: 100%;
    padding: 0 20px;
    max-width: 790px;
    margin: 0 auto;
}
