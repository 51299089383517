.radio {
    display: none;
}

.panel {
    display: none;
}

.panels.faq-tabs {
    display: flex;

    & .panel {
        display: block;
        visibility: hidden;
        margin-right: -100%;
        width: 100%;
    }
}

#one:checked ~ .panels #faq-one-panel,
#two:checked ~ .panels #faq-two-panel,
#three:checked ~ .panels #faq-three-panel {
    visibility: visible;
}

#one:checked ~ .panels #one-panel,
#two:checked ~ .panels #two-panel,
#three:checked ~ .panels #three-panel {
    display: flex;
}

#one:checked ~ .tabs #one-tab,
#two:checked ~ .tabs #two-tab,
#three:checked ~ .tabs #three-tab {
    background: #ffffff;
    color: #7a12ef;
    box-shadow: 0 5px 10px rgba(125, 20, 236, 0.05);
}

.price-panel {
    display: none;
}

#price-one:checked ~ .price-panels #price-one-panel,
#price-two:checked ~ .price-panels #price-two-panel {
    display: flex;
}

#price-one:checked ~ div .price-tabs #price-one-tab,
#price-two:checked ~ div .price-tabs #price-two-tab {
    background: #ffffff;
    color: #7a12ef;
    box-shadow: 0 5px 10px rgba(125, 20, 236, 0.05);
    width: min-content;

    @include max-w-xs {
        width: 100%;
        max-width: none;
    }
}

#price-one-tab,
#price-two-tab {
    width: min-content;

    @include max-w-xs {
        width: 100%;
        max-width: none;
    }
}
