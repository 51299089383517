.justify {
    &-start {
        justify-content: flex-start;
    }

    &-end {
        justify-content: flex-end;
    }

    &-center {
        justify-content: center;
    }

    &-between {
        justify-content: space-between;
    }

    &-around {
        justify-content: space-around;
    }

    &-evenly {
        justify-content: space-evenly;
    }

    &-self-end {
        justify-self: flex-end;
    }
}

.sm-max\:justify {
    @include max-w-sm {
        &-start {
            justify-content: flex-start;
        }

        &-center {
            justify-content: center;
        }
    }
}

.md-max\:justify {
    @include max-w-md {
        &-center {
            justify-content: center;
        }

        &-end {
            justify-content: flex-end;
        }
    }
}
