.success-message {
    display: none;
    position: absolute;
    top: 50px;
    color: rgb(21, 168, 21);
    opacity: 0;
    margin-top: 21px;

    @include fade-in(0.5s);

    &.js-popup-opened {
        display: flex;
        opacity: 1;
    }

    @include max-w-md {
        margin-top: 20px;
        font-size: 14px;
    }
}
