.flex {
    &-1 {
        flex: 1 1 0%;
        min-width: 0;
    }

    &-auto {
        flex: 1 1 auto;
        min-width: 0;
    }

    &-initial {
        flex: 0 1 auto;
        min-width: 0;
    }

    &-none {
        flex: none;
    }

    &-wrap {
        flex-wrap: wrap;
    }
}

.shrink {
    flex-shrink: 1;

    &-0 {
        flex-shrink: 0;
    }
}

@include max-w-sm {
    .sm-max\:flex-wrap {
        flex-wrap: wrap;
    }
}
