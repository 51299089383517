.article {
    &__container {
        max-width: 789px;
        width: 100%;
        z-index: 10;

        & > :first-child {
            margin-top: 0;
        }
    }

    &__image {
        & > a {
            & > img {
                border-radius: 15px;
                object-fit: cover;
                height: 340px;
                width: 100%;

                @include max-w-xs {
                    height: 150px;
                }
            }
        }

        & > p {
            & iframe {
                border-radius: 15px;
                height: 340px !important;
                max-height: 340px;
                width: 100% !important;

                @include max-w-xs {
                    height: 200px !important;
                }
            }
        }
    }

    &__description {
        padding: 30px;

        @include max-w-sm {
            padding: 20px;
        }
    }

    &__keywords {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;

        & > div {
            padding: 10px 20px;
            background: $milk-white-100;
            border-radius: 8px;

            & > p {
                letter-spacing: 2px;
                color: #697397;
                font-weight: 600;
                font-size: 12px;
                line-height: 100%;
                text-transform: uppercase;
            }
        }
    }

    &__soc-box {
        background: $white-radial-80;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.03);
        border-radius: 10px;
        padding: 10px;
        cursor: pointer;

        & > a {
            & > img {
                width: 14px;
                height: 14px;
            }
        }
    }

    &__most-read {
        padding: 20px;
        background: $white-radial-80;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.03);
        border-radius: 10px;
        max-width: 276px;

        @include max-w-xs {
            max-width: 100%;
        }

        & p {
            font-weight: 600;
            font-size: 18px;
            line-height: 130%;
            color: $text-black-100;
        }
    }

    &__join {
        padding: 20px;
        background: $bright-blue-100;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.03);
        border-radius: 10px;
        max-width: 276px;

        @include max-w-xs {
            max-width: 100%;
        }

        & p {
            font-weight: 600;
            font-size: 18px;
            line-height: 130%;
            color: $text-black-100;
        }
    }

    &__item {
        background: $white-radial-80;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.03);
        border-radius: 15px;
        margin-top: 30px;
    }
}

.blog-filters {
    display: block;

    @include max-w-sm {
        display: none;
    }
}

.follow {
    &__container {
        top: 130px;
        position: sticky;
        right: 0;
        height: max-content;
    }
}

.blog {
    &.swiper-wrapper {
        & > :not(:last-child) {
            margin-right: 31px;
        }
    }
}

#scroll-progress {
    position: fixed;
    top: 75px;
    width: 0%;
    height: 2px;
    background: linear-gradient(268.01deg, #8015e8 -7.03%, #670aff 137.11%);
    z-index: 1000;
    border-radius: 2px;

    @include max-w-sm {
        top: 64px;
    }

    @include max-w-xs {
        top: 60px;
    }
}

.follow__container .article__soc-box {
    @include max-w-sm {
        & > a {
            & > img {
                width: 24px;
                height: 24px;
            }
        }
    }
}

// .follow__container .article__soc-box {
//     @include max-w-sm {
//         & > a {
//             & > img {
//                 width: 24px;
//                 height: 24px;
//             }
//         }
//     }
// }
