.header {
    transition: 0.3s;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;

    &__inner {
        width: 100%;
    }

    // &.scroll-down {
    //     transform: translate3d(0, -100%, 0);
    // }

    // &.scroll-up {
    //     transform: none;
    //     // background-color: $bg-dark;
    //     // background-image: url("../images/starlight-bg.png");
    //     background-position: top;
    // }
}

.red {
    background: red;
}
