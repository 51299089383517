.text-container.text-page {
    margin-top: 60px;

    // & > ol,
    // > ol > li > ol {
    //     list-style: none;
    //     counter-reset: li;
    // }

    // & > ol > li > ol > li {
    //     // margin-top: 30px;

    //     & > p {
    //         display: inline !important;
    //     }
    // }

    // & > ol > li {
    //     margin-top: 50px;
    // }

    // & > ol > li,
    // > ol > li > ol {
    //     &::before {
    //         counter-increment: li;
    //         content: counters(li, ".") ". ";
    //     }
    // }

    & ol {
        list-style: decimal;

        & > li {
            &::marker {
                font-size: 16px;
                font-weight: 400;
                line-height: 160%;
                color: #636e95;
            }
        }
    }

    & ol li {
        margin-left: 20px;
        margin-top: 30px;
        font-size: 16px;
        font-weight: 400;
        line-height: 160%;
        color: #636e95;

        & > p {
            margin-left: 20px;
            font-size: 15px;
            margin-top: 20px;
        }
    }

    & ul {
        & > li {
            &:not(:first-child) {
                margin-top: 15px;
            }
        }
    }

    & em {
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        color: #636e95;
    }

    & > h1 {
        @include responsive-fz(50, 32);

        line-height: 130%;
        font-weight: 600;
        color: $text-black-100;
    }

    & > h4 {
        margin-top: 50px;
    }

    & > h6 {
        margin-top: 30px;
        margin-bottom: 50px;
    }

    @include min-w-xl {
        h1 {
            font-size: 50px;
        }
    }
}
