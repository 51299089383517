.lh {
    &-100 {
        line-height: 100%;
    }

    &-160 {
        line-height: 160%;
    }

    &-130 {
        line-height: 130%;
    }
}
