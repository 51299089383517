.bio-modal {
    display: none;
    position: fixed;
    z-index: 999999999999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #f5f8faf5;
}

.meetings-iframe-container {
    display: flex;
    align-items: center;

    // &::before {
    //     content: "";
    //     position: absolute;
    //     background: $white-100;
    //     // width: 840px;
    //     // height: 620px;
    //     width: 99%;
    //     height: 620px;
    //     z-index: -1;
    //     margin-left: 5px;
    //     margin-top: -103px;

    //     @media (max-width: 657px) {
    //         height: 570px;
    //         margin-top: -143px;
    //         margin-left: 5px;
    //         width: 97%;
    //     }
    // }
}

.bio-content {
    // background-color: $bg-dark-80;
    border-radius: 8px;
    position: relative;
    top: 54%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 850px;
    overflow-y: auto;

    @media (max-width: 649px) {
        max-width: 400px;
    }

    @media (max-height: 600px) {
        top: 70%;
    }

    @include max-w-2xs {
        top: 61%;
    }

    &__text {
        padding: 25px;

        & img {
            width: 66px;
            height: 66px;
            border-radius: 50%;
        }

        @include max-w-sm {
            padding: 4px;
        }
    }
}

.close {
    float: right;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    top: 34px;
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    right: 40px;

    & > img {
        position: relative;
        right: -20px;
    }

    @include max-w-md {
        right: 30px;
    }
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
