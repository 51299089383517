.how {
    &.card-box {
        width: 300px;
        height: 250px;
        background: linear-gradient(65.14deg, rgba(255, 255, 255, 0.5) 4.44%, #ffffff 85.7%);
        position: relative;
    }
}

.how-hero {
    left: 50%;
    transform: translateX(-50%);

    &__inner {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        overflow-x: auto;
        scrollbar-width: none;
        filter: drop-shadow(0 5px 60px rgba(0, 0, 0, 0.06));

        & > :nth-child(1) {
            & .card-box__inner {
                background: rgba(37, 205, 175, 0.1);
                padding: 10px 0 5px;

                & > img {
                    width: 200px;
                    object-fit: contain;
                }
            }
        }

        & > :nth-child(2) {
            & .card-box__inner {
                background: rgba(125, 63, 255, 0.1);
                padding: 10px 0 0;

                & > img {
                    width: 135px;
                    object-fit: contain;
                }
            }
        }

        & > :nth-child(3) {
            & .card-box__inner {
                background: rgba(162, 229, 121, 0.1);
                padding: 10px 0 0;

                & > img {
                    width: 200px;
                    object-fit: contain;
                }
            }
        }

        & > :nth-child(4) {
            & .card-box__inner {
                background: rgba(77, 84, 255, 0.1);
                padding: 6px 0 0 0;

                & > img {
                    width: 200px;
                }
            }
        }

        & > :last-child {
            & > div {
                &:first-child {
                    & > img {
                        display: none;
                    }
                }
            }
        }

        & > :not(:last-child) {
            & .how.card-box {
                & > img {
                    position: absolute;
                    display: inline-block;
                    right: -21px;
                    z-index: 2000;
                    width: 22px;
                    height: 68px;
                }
            }
        }

        &::-webkit-scrollbar {
            display: none;
        }

        @include max-w-lg {
            margin-right: -20px;
            padding-right: 20px;
            left: 0;
            transform: translateX(0%);
        }
    }

    &__box {
        // filter: drop-shadow(0 5px 60px rgba(0, 0, 0, 0.06));
    }
}

.how-section {
    background-color: $milk-white-100;
    transform: skewY(-2deg) translateY(-22px);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    & > div {
        transform: skewY(2deg) translateY(22px);
    }

    @include max-w-sm {
        transform: skewY(-5deg) translateY(-22px);

        & > div {
            transform: skewY(5deg) translateY(22px);
        }
    }
}
