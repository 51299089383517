.min-w {
    &-0 {
        min-width: 0;
    }

    &-128 {
        min-width: 128px;
    }

    &-133 {
        min-width: 133px;
    }

    &-257 {
        min-width: 257px;
    }

    &-295 {
        min-width: 295px;
    }
}

.min-h {
    &-52 {
        min-height: 52px;
    }

    &-420 {
        min-height: 420px;
    }

    &-495 {
        min-height: 495px;
    }

    &-104 {
        min-height: 104px;
    }
}

.max-h {
    &-400 {
        max-height: 400px;
    }

    &-250 {
        max-height: 250px;
    }
}

.max-w {
    &-0 {
        max-width: none;
    }

    &-90 {
        max-width: 90px;
    }

    &-200 {
        max-width: 200px;
    }

    &-224 {
        max-width: 224px;
    }

    &-250 {
        max-width: 250px;
    }

    &-276 {
        max-width: 276px;
    }

    &-300 {
        max-width: 300px;
    }

    &-304 {
        max-width: 304px;
    }

    &-310 {
        max-width: 310px;
    }

    &-318 {
        max-width: 318px;
    }

    &-379 {
        max-width: 379px;
    }

    &-400 {
        max-width: 400px;
    }

    &-420 {
        max-width: 420px;
    }

    &-470 {
        max-width: 470px;
    }

    &-482 {
        max-width: 482px;
    }

    &-522 {
        max-width: 522px;
    }

    &-543 {
        max-width: 543px;
    }

    &-570 {
        max-width: 570px;
    }

    &-584 {
        max-width: 584px;
    }

    &-700 {
        max-width: 700px;
    }

    &-790 {
        max-width: 790px;
    }

    &-994 {
        max-width: 994px;
    }

    &-1100 {
        max-width: 1050px;
    }
}

.w-full {
    width: 100%;
}

.h-full {
    height: 100%;
}

.xs\:w-full {
    @include max-w-xs {
        width: 100%;
    }
}

.sm-max\:w-full {
    @include max-w-sm {
        width: 100%;
    }
}

.lg-max\:w-full {
    @include max-w-lg {
        width: 100%;
    }
}

.sm-max\:max-w {
    @include max-w-sm {
        &-0 {
            max-width: none;
        }

        &-200 {
            max-width: 200px;
        }

        &-335 {
            max-width: 335px;
        }
    }
}

.md-max\:max-w {
    @include max-w-md {
        &-0 {
            max-width: none;
        }

        &-400 {
            max-width: 400px;
        }

        &-340 {
            max-width: 340px;
        }
    }
}

.md-max\:min-w {
    @include max-w-md {
        &-0 {
            min-width: 0;
        }

        &-340 {
            min-width: 340px;
        }
    }
}

.sm-max\:min-w {
    @include max-w-sm {
        &-0 {
            min-width: 0;
        }
    }
}

.sm-max\:min-h {
    @include max-w-sm {
        &-495 {
            min-height: 495px;
        }
    }
}

.xs-max\:max-w {
    @include max-w-xs {
        &-none {
            max-width: none;
        }

        &-280 {
            max-width: 280px;
        }
    }
}
