.items {
    &-start {
        align-items: flex-start;
    }

    &-end {
        align-items: flex-end;
    }

    &-center {
        align-items: center;
    }

    &-baseline {
        align-items: baseline;
    }

    &-stretch {
        align-items: stretch;
    }
}

.md-max\:items {
    @include max-w-md {
        &-center {
            align-items: center;
        }
    }
}

.sm-max\:items {
    @include max-w-sm {
        &-start {
            align-items: flex-start;
        }
    }
}

.xs-max\:items {
    @include max-w-xs {
        &-end {
            align-items: flex-end;
        }
    }
}
