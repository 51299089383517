.why-viva {
    &__inner {
        background: $white-100;
        display: grid;
        min-width: 1064px;
        grid-template-columns: 1.057fr 1fr 1fr 1fr;
        grid-template-rows: auto;
        border-radius: 20px;

        @include max-w-xs {
            grid-template-columns: 236px 0.5fr 0.5fr 0.5fr;
            min-width: 710px;
        }

        & > :last-child {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;

            @media (max-width: 1100px) {
                border-radius: 0;
            }
        }
        // min-width: 1200px;
        // width: max-content;
    }

    &__testimonials {
        max-width: 994px;

        @include max-w-sm {
            & .our-customers__bottom {
                & > div {
                    font-size: 14px;
                }
            }
        }

        & .our-customers {
            &__logo-image {
                width: 88px;
                object-fit: contain;

                @include max-w-sm {
                    width: 59px;
                }
            }
        }
    }

    &__compare {
        box-shadow: 0 5px 60px rgba(125, 20, 236, 0.1);
        max-width: 1200px;
        width: 100%;
        margin: 60px 0 100px 0;
        border-radius: 20px;

        @include max-w-md {
            margin: 40px 0 100px 0;
        }
    }

    &__plans {
        // overflow-x: auto;
        // display: grid;
        // grid-template-columns: 1fr 1fr 1fr;
        // grid-template-rows: auto;
    }

    &__hero {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;

        @media (max-width: 1100px) {
            padding-right: 0;
        }
    }

    &__rows {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(11, 1fr);
        background: $white-100;
        z-index: 0;

        & > :first-child {
            border-top: none;
        }

        &--sticky {
            position: sticky;
            left: 0;
            border-bottom-left-radius: 20px;
            border-top-left-radius: 20px;
            // border: 2px solid $white-100;
            z-index: 1;
        }

        &.recommended {
            background: linear-gradient(268.01deg, rgba(128, 21, 232, 0.05) -7.03%, rgba(103, 10, 255, 0.05) 137.11%);
            border: 2px solid #7a12ef;
            border-radius: 20px;
            // margin: 1px 0;
        }
    }

    &__container {
        overflow-x: auto;
        scrollbar-width: none;
        width: 100%;
        max-width: 1200px;
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__icon {
        & p {
            letter-spacing: 2px;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 12px;
            line-height: 100%;
            color: #3f2d54;
        }
    }

    &__video-mask {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        top: 0;
        padding: 25px;
        background: linear-gradient(10.85deg, rgba(0, 0, 0, 0.5) 7.43%, rgba(0, 0, 0, 0) 91.34%);
        border-radius: 20px;
        width: 100%;
        height: 300px;
        z-index: 1;

        @include max-w-sm {
            height: 266px;
        }

        & > img {
            width: 83px;
            height: 25px;
        }

        & p:first-of-type {
            font-weight: 600;
            font-size: 16px;
            line-height: 160%;
            color: $white-100;
        }

        & p:last-of-type {
            font-weight: 400;
            font-size: 12px;
            line-height: 100%;
            color: rgba(255, 255, 255, 0.9);
        }
    }

    &__video-placeholder {
        padding-left: 15px;
        border-left: 5px solid $turquoise-100;
    }

    &__slider {
        &.two-slides {
            @media (max-width: 850px) {
                width: 100%;
            }

            @media (min-width: 850px) {
                & .swiper-pagination {
                    display: none;
                }
            }
        }

        &.blog-testimonials {
            @media (max-width: 1283px) {
                width: 100%;
            }
        }
    }

    &__slide {
        min-width: 379px;
        max-width: none;
        min-height: 300px;

        & > a > img {
            border-radius: 20px;
            width: 379px;
            height: 300px;
        }

        @include max-w-sm {
            position: relative;
            width: 100%;
            min-width: 335px;
            max-width: none;
            // height: 266px;

            & > a > img {
                width: 335px;
                height: 266px;
            }
        }

        @media (max-width: 375px) {
            min-width: 325px;
        }

        @media (max-width: 366px) {
            min-width: 315px;
        }

        @media (max-width: 356px) {
            min-width: 300px;
        }

        @media (max-width: 326px) {
            min-width: 275px;
        }
    }

    & .swiper-wrapper {
        width: 100%;
        max-width: none;
        display: flex;
        justify-content: space-between;

        & > :not(:last-child) {
            margin-right: 32px;
        }
    }

    & .swiper-pagination {
        @media (min-width: 1188px) {
            display: none;
        }
    }
}

.video {
    &__block {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 20px;
        object-fit: contain;
        // background: black;

        @include max-w-sm {
            max-height: 266px;
        }
    }

    &__title {
        position: relative;
        bottom: -30px;
    }

    &__link {
        height: 300px;
    }
}

.video-container {
    &:not(:fullscreen) {
        & .video__block {
            position: absolute;
            width: 200px;
            height: 200px;
            left: auto;
            top: auto;
            border-radius: 20px;
            z-index: -1;
            max-height: 266px;
            display: none;
        }

        & .video-mask {
            display: flex !important;
        }

        & .video-close {
            display: none !important;
        }
    }
}

.video-mask {
    cursor: pointer;
    // min-height: 265px;
    min-height: 300px;

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        border-radius: 20px;

        & > img {
            width: 379px;
            height: 300px;
            border-radius: 20px;
            object-fit: cover;

            @include max-w-sm {
                width: 335px;
                height: 266px;
            }
        }
    }
}

.executives {
    background: $milk-white-100;
    transform: skewY(-2deg) translateY(42px);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    & > div {
        transform: skewY(2deg) translateY(-42px);
    }

    @include max-w-md {
        transform: skewY(-5deg) translateY(42px);

        & > div {
            transform: skewY(5deg) translateY(-42px);
        }
    }

    &__box-container {
        flex-wrap: wrap;
        padding: 0 27px;

        @include max-w-sm {
            padding: 0;
            margin-top: 20px;
        }

        @include max-w-2xs {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__box {
        padding: 34px 20px;
        box-shadow: 0 5px 10px rgba(125, 20, 236, 0.05);
        border-radius: 10px;
        min-width: 150px;
        min-height: 150px;
        margin-top: 40px;
        transition: background 0.3s;

        @include max-w-xs {
            margin-top: 20px;
            padding: 37.5px 23.5px;
        }

        & > img {
            width: 40px;
            height: 35px;
            object-fit: contain;
            margin-top: 25px;
        }

        &:hover {
            background: $white-100;
        }

        &:nth-child(2) {
            position: relative;
            top: 20px;

            @include max-w-2xs {
                top: 0;
            }
        }

        &:nth-child(4) {
            @media (max-width: 1043px) {
                position: relative;
                top: 20px;
            }

            @include max-w-2xs {
                top: 0;
            }
        }

        &:nth-child(5) {
            position: relative;
            top: 20px;

            @media (max-width: 1043px) {
                top: 0;
            }
        }

        &:nth-child(6) {
            @media (max-width: 1043px) {
                position: relative;
                top: 20px;
            }

            @include max-w-2xs {
                top: 0;
            }
        }

        & > p {
            font-weight: 400;
            font-size: 14px;
            line-height: 160%;
            color: $gray-blue-100;
        }
    }
}

.video-close {
    display: none;
    float: right;
    font-size: 28px;
    font-weight: bold;
    position: fixed;
    top: 34px;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    right: 40px;
    z-index: 99999;
    cursor: pointer;

    @include max-w-sm {
        right: 10px;
    }

    & > img {
        width: 20px;
        height: 20px;
    }
}
