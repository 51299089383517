%fixedXY {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

@include placeholder {
    opacity: 1;
    line-height: normal;
}

.no-scroll {
    // @extend %fixedXY;

    & #scroll-progress {
        z-index: 0;
    }

    overflow-y: hidden;
}

.not-allowed {
    pointer-events: none !important;
}

.container {
    width: 100%;
    max-width: 1240px;
    padding: 0 20px;
    margin: 0 auto;

    @include max-w-lg {
        padding: 0 20px;
    }
}

.logo {
    display: inline-flex;
    // max-height: 2.125em;
    flex-shrink: 0;
}

.crs-pointer {
    cursor: pointer;
}

.mark {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 100%;
    color: red;
}

span {
    &.error {
        display: inline-block;
        position: absolute;
        top: 50px;
        font-weight: 400;
        font-size: 16px;
        line-height: 142.9%;
        margin-top: 20px;
        color: red;
    }
}

.events {
    &-none {
        pointer-events: none;
    }

    &-auto {
        pointer-events: auto;
    }
}

.desktop {
    display: block;

    @include max-w-sm {
        display: none !important;
    }
}

.mobile {
    display: none !important;

    @include max-w-sm {
        display: inline-flex !important;
    }
}
