// Indents
$spaces: (10, 12, 14, 20, 26, 32, 60);
$sides: ("column", "row");

@each $space in $spaces {
    @each $side in $sides {
        .#{$side}-gap-#{$space} {
            #{$side}-gap: #{$space}px;
        }

        .gap-#{$space} {
            gap: #{$space}px;
        }
    }
}

@each $space in $spaces {
    @each $side in $sides {
        @include max-w-sm {
            .sm-max\:#{$side}-gap-#{$space} {
                #{$side}-gap: #{$space}px;
            }

            .sm-max\:gap-#{$space} {
                gap: #{$space}px;
            }
        }

        @include max-w-2xs {
            .xs-max\:#{$side}-gap-#{$space} {
                #{$side}-gap: #{$space}px;
            }

            .xs-max\:gap-#{$space} {
                gap: #{$space}px;
            }
        }
    }
}
