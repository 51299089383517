.flex {
    &-row {
        flex-direction: row;
    }

    &-row-reverse {
        flex-direction: row-reverse;
    }

    &-col {
        flex-direction: column;
    }

    &-col-reverse {
        flex-direction: column-reverse;
    }
}

.xs-max\:flex {
    @include max-w-xs {
        &-col {
            flex-direction: column;
        }

        &-col-reverse {
            flex-direction: column-reverse;
        }
    }
}

.sm-max\:flex {
    @include max-w-sm {
        &-col {
            flex-direction: column;
        }

        &-col-reverse {
            flex-direction: column-reverse;
        }
    }
}

.md-max\:flex {
    @include max-w-md {
        &-col {
            flex-direction: column;
        }

        &-col-reverse {
            flex-direction: column-reverse;
        }
    }
}
