.social-box-text {
    & > h3 {
        & > p {
            color: rgba(205, 208, 220, 0.7);

            & > strong {
                color: $white-100;
                font-weight: 600;
            }
        }
    }

    @include max-w-sm {
        padding: 0 30px;
    }
}

.social {
    &__box {
        column-gap: 32px;

        @include max-w-sm {
            max-width: 335px;
            flex-direction: column;
        }
    }

    &__inner {
        max-width: 788px;
        width: 100%;
        display: flex;
        justify-content: center;
        column-gap: 32px;

        & > :nth-child(1) {
            & .card-box {
                &__inner {
                    background: $turquoise-10;
                    display: flex;
                    align-items: flex-end;

                    & > img {
                        width: 258px;
                        height: 176px;
                        position: relative;
                        bottom: 0;
                    }
                }
            }
        }

        & > :nth-child(2) {
            @media (max-width: 845px) {
                margin-top: 30px;
            }

            & .card-box {
                &__inner {
                    background: rgba(125, 63, 255, 0.1);
                    display: flex;
                    align-items: flex-end;

                    & > img {
                        width: 258px;
                        height: 160px;
                    }
                }
            }
        }
    }

    &.card-box {
        width: 378px;
        height: 250px;
        background: $white-100;

        @include max-w-xs {
            width: 100%;
        }
    }
}

.space-empty {
    @include max-w-md {
        display: none;
    }
}

.timeline {
    background-image: url("/images/timeline-bg.png");
    background-repeat: no-repeat;
    background-size: contain;

    @media (max-width: 385px) {
        padding: 0 20px;
    }

    @include max-w-md {
        background-image: url("/images/svg/timeline-bg-mob.svg");
        background-position: center;
        // background-size: auto;
    }

    &__container {
        width: 100%;
        max-width: 1220px;
        // height: 1166px;
    }

    &__item {
        @include max-w-md {
            height: 344px;
            // margin-top: 12px;
            align-items: center;
        }
    }

    &__content {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1.7fr 1.7fr 0.7fr;
        justify-content: center;
        // grid-auto-rows: 1fr;
        // column-gap: 10%;
        // row-gap: 20%;
        // grid-column-gap: 8em;
        // grid-row-gap: 4em;
        height: max-content;
        padding-top: 82px;

        @include max-w-lg {
            grid-template-rows: 1.6fr 1.6fr 0.7fr;
        }

        @include max-w-1096 {
            grid-template-rows: 1.4fr 1.5fr 0.7fr;
        }

        @media (max-width: 1025px) {
            grid-template-rows: 1.4fr 1.3fr 0.7fr;
        }

        @include max-w-md {
            padding-top: 26px;
            grid-template-columns: 1fr;
            grid-template-rows: 334px 334px 334px 334px 334px 334px 314px 314px;
            // background-image: url("/images/svg/timeline-bg-mob.svg");
            // background-position: center;
            // background-size:auto;
        }

        @media (max-width: 368px) {
            grid-template-rows: 284px 304px 304px 304px 304px 304px 310px 295px;
        }

        @media (max-width: 338px) {
            grid-template-rows: 310px repeat(5, 300px) 330px 290px;
        }
        // @include max-w-lg {
        //     grid-template-rows: 1.8fr 1.9fr 1fr;

        // }

        // & :nth-child(n + 4) {
        //     margin-top: 100px;
        // }
    }

    &__card {
        max-width: 276px;
        width: 100%;
        max-height: 254px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 25px;
        border-radius: 20px;
        transition: background 0.3s;

        & strong {
            color: #8015e8;
            font-weight: 600;
        }

        & a {
            color: #8015e8;
        }

        @include max-w-md {
            background-color: #ffffff;
            box-shadow: 0 5px 20px rgba(125, 20, 236, 0.05);
        }

        &:hover {
            background-color: #ffffff;
            box-shadow: 0 5px 20px rgba(125, 20, 236, 0.05);
        }
    }

    &__card-container {
        width: 100%;
        max-width: 1220px;
    }

    &__card-image {
        padding: 15px;
        background: $white-100;
        box-shadow: 0 5px 10px rgba(125, 20, 236, 0.05);
        border-radius: 15px;
    }
}
