.footer {
    flex-shrink: 0;
    background: #051651;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-top: 100px;
    padding-bottom: 80px;
    position: relative;
    z-index: 123;

    @include max-w-sm {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    & a:hover {
        color: $main-purple-100;
    }

    &__holder {
        // @include max-w-sm {
        //     padding-bottom: 0.938em;
        // }

        @include max-w-md {
            grid-template-columns: 1fr;
        }

        display: grid;
        grid-auto-columns: 1fr;
        grid-column-gap: 32px;
        grid-template-columns: 0.235fr 0.765fr;
        grid-template-rows: auto;
    }

    &__policy {
        display: grid;
        grid-auto-columns: 1fr;
        grid-column-gap: 32px;
        grid-template-columns: 0.235fr 0.765fr;
        grid-template-rows: auto;
        align-items: center;

        @include max-w-sm {
            display: flex;
            flex-direction: column-reverse;
            grid-row-gap: 60px;
            align-items: flex-start;
        }

        @include max-w-xs {
            grid-template-columns: 1fr;
            grid-row-gap: 60px;
        }
    }

    &__container {
        display: grid;
        grid-auto-columns: 1fr;
        grid-column-gap: 32px;
        grid-template-columns: 0.23fr 0.23fr 0.23fr 0.37fr;
        grid-template-rows: auto;

        & > :not(:last-child) {
            max-width: 173px;
        }

        & > :last-child {
            max-width: 276px;

            @include max-w-xs {
                max-width: none;

                & > :first-child {
                    font-size: 20px;
                }
            }
        }

        @include max-w-xs {
            justify-content: space-between;
        }

        @include max-w-md {
            display: flex;
            flex-wrap: wrap;
            row-gap: 60px;
            column-gap: 20px;
            margin-top: 60px;

            & > :last-child {
                display: flex;
                flex-direction: column;
                order: -1;
                width: 100%;
            }

            & :nth-child(3) {
                width: auto !important;
            }
        }

        @include max-w-sm {
            margin-top: 0;
            row-gap: 0;
        }
    }

    &__item {
        @include max-w-sm {
            margin-top: 2.188em;
        }

        @include max-w-xs {
            margin-top: 60px;
            grid-column: span 2;
            grid-row: span 1;
        }

        @include max-w-md {
            width: 120px;
        }
    }

    &__nav {
        margin-top: 1.389em;

        & > ul {
            & > li {
                line-height: 160%;

                &:nth-child(n + 2) {
                    @include max-w-xs {
                        margin-top: 0.833em;
                    }

                    margin-top: 0.714em;
                }

                font-size: 0.778em;

                @include max-w-xs {
                    font-size: 15px;
                }
            }
        }
    }

    &__soc {
        background-color: #051651;

        & > ul {
            display: flex;

            & :first-child {
                margin-left: 0;
            }

            & > li {
                margin-left: 20px;

                @include max-w-xs {
                    margin-left: 30px;
                }

                & > a {
                    display: block;
                    width: 34px;
                    height: 34px;
                    padding: 10px;
                    border-radius: 10px;
                    background: rgba(255, 255, 255, 0.1);

                    @include max-w-xs {
                        width: 44px;
                        height: 44px;

                        & > img {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
        }
    }
}

.privacy-link {
    width: max-content;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
}
