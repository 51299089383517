.team {
    overflow-x: auto;
    cursor: grab;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    & :not(:last-child) {
        margin-right: 32px;
    }
}

.team-wrapper {
    & img {
        border-radius: 15px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        // background: #111111;
        // object-fit: contain;
    }

    & .swiper-slide {
        min-width: 378px;
        height: 250px;

        @include max-w-xs {
            min-width: 335px;
            height: 252px;
        }
    }
}

.team-wrap {
    padding-bottom: 62px;
}

.swiper-arrows {
    &.team-pagination {
        display: flex;
        position: static;
        justify-content: center;
        width: 100%;
        max-width: none;
        margin-top: 18px;
    }
}
