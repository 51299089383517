.price {
    background-color: $milk-white-100;
    transform: skewY(-2deg) translateY(42px);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    & > div {
        transform: skewY(2deg) translateY(-42px);
    }

    @include max-w-md {
        transform: skewY(-5deg) translateY(42px);

        & > div {
            transform: skewY(5deg) translateY(-42px);
        }
    }

    &__present-icon {
        background: $white-100;
        padding: 5px;
        box-shadow: 0 5px 10px rgba(125, 20, 236, 0.05);
        border-radius: 6px;
        margin-left: 10px;
    }

    &__recommended-item {
        position: absolute;
        background: linear-gradient(268.01deg, #8015e8 -7.03%, #670aff 137.11%);
        border-radius: 8px;
        padding: 10px 20px;
        font-weight: 600;
        font-size: 12px;
        line-height: 100%;
        color: $white-100;
        text-transform: uppercase;
        left: 50%;
        top: -16px;
        letter-spacing: 2px;
        transform: translateX(-50%);
        width: max-content;

        @include max-w-md {
            padding: 10px 11px;
        }

        @media (max-width: 373px) {
            font-size: 2.8vw;
            text-align: center;
        }
    }

    &__plan {
        letter-spacing: 2px;
    }

    &__mobile {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include min-w-md {
            display: none;
        }
    }

    &__mobile-inner {
        background-color: $white-100;
        border-radius: 20px;
        max-width: 335px;
        width: 100%;

        & .recommended {
            background: url("/images/recommended-bg-mob.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            position: relative;
        }
    }

    &__inner {
        background: $white-100;
        border: 1px solid rgba(0, 0, 0, 0.05);
        box-shadow: 0 5px 60px rgba(125, 20, 236, 0.1);
        border-radius: 20px;
        display: grid;
        grid-template-columns: 1.057fr 1fr 298px 1fr;
        grid-template-rows: auto;

        // min-width: 1200px;
        // width: max-content;

        @include max-w-md {
            display: none;
        }
    }

    &__rows {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 0.276fr 0.09fr 0.09fr 0.09fr 0.09fr 0.09fr 0.09fr 0.09fr 0.09fr;

        &.recommended {
            background: url("/images/recommended-bg.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;

            & .price__cost-year {
                & span {
                    color: #3f2d54;
                }
            }
        }
    }

    &__header {
        padding: 30px;
        min-height: 262px;

        @include max-w-md {
            min-height: 0;
        }
    }

    &__icon {
        padding: 14px;
        background: #ffffff01;
        box-shadow: 0 5px 10px rgba(125, 20, 236, 0.05);
        border-radius: 15px;
    }

    &__item {
        padding: 30px;
        min-height: 86px;
        border-top: 1px solid rgba(0, 0, 0, 0.05);

        & > p {
            display: flex;
            align-items: center;

            & > img {
                margin-right: 10px;
            }
        }

        @include max-w-md {
            padding: 30px 20px;
        }

        &--left {
            text-align: left;
        }

        &--mobile {
            min-height: 44px;
            padding: 11px 20px;
            border: none;

            @include max-w-md {
                justify-content: flex-start;
            }

            & > p {
                font-weight: 600;
                font-size: 14px;
                line-height: 160%;
                color: #3f2d54;
                margin-left: 11px;

                & > span {
                    font-weight: 400;
                    line-height: 160%;
                    font-size: 14px;
                    color: #636e95;
                    margin-left: 5px;
                }
            }
        }
    }

    &__cost-year {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: 100%;
        font-weight: 600;
        font-size: 20px;
        line-height: 130%;

        & > span {
            margin-left: 5px;
            color: #8d8d91;
            text-decoration: line-through;
        }
    }

    &__item-mobile {
        padding: 21px 0;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
    }
}

.price-menu {
    background: #ffffff;
    border-radius: 12px;
    padding: 4px;

    @include max-w-xs {
        & > label {
            width: 100%;
        }

        width: 100%;
    }
}

.price-sale {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    color: $main-purple-100;
    letter-spacing: 2px;
}
