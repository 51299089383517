.navbar {
    padding: 15px 0;
    background: rgba(241, 242, 250, 0.1);
    border-bottom: 0.4px solid $white-100;
    backdrop-filter: blur(25px);

    @include max-w-sm {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        width: 100%;
        margin: 0 auto;
        padding: 20px;
    }

    // &__backdrop {
    //     position: absolute;
    //     background: rgba(40, 40, 40, 0.6);
    //     width: 100%;
    //     height: 100vh;
    // }

    &__nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1200px;
        width: 86.11%;
        margin: 0 auto;

        @include max-w-sm {
            padding: 22px 20px;
        }

        @include max-w-sm {
            align-items: flex-start;
            width: 100vw;
            left: 2.6%;
            position: absolute;
            flex-direction: column;
            text-align: center;
            background-color: $white-100;
            top: -10px;
            z-index: -1;
            margin: 0;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
            padding: 20px 20px 68px;
            overflow: hidden;
            will-change: transform;
            transition: transform 0.3s cubic-bezier(0.35, 0.15, 0.34, 1.06), z-index 0s 0.3s;
            transform: translate(-2.6%, -100%);

            &.is-active {
                transform: translate(-2.6%, -1%);
                z-index: 20;
                padding: 40px 20px 50px;
                transition: transform 0.3s cubic-bezier(0.35, 0.15, 0.34, 1.06), z-index 0s 0s;
                // background-image: url("../images/menu-bg.png");
                background-repeat: no-repeat;
                background-size: cover;

                & .navbar__logo {
                    & > svg {
                        & path {
                            fill: #231f20 !important;
                        }
                    }
                }
            }
        }

        // @include max-w-xs {
        //     width: 100.5vw;
        //     left: 15px;
        // }

        & > ul {
            display: flex;
            align-items: center;

            @include max-w-sm {
                & > li:first-of-type {
                    margin-top: 0;
                }
            }

            & li:last-of-type {
                margin-right: 0;
            }

            @include max-w-sm {
                flex-direction: column;
                width: 100%;
                align-items: flex-start;
                margin-top: 50px;
                margin-bottom: 50px;
            }

            & > li {
                text-align: center;

                @include max-w-sm {
                    margin-top: 30px;
                }

                @include min-w-sm {
                    margin-right: 2.5em;
                }

                & > a {
                    color: $text-black-100;

                    @include max-w-sm {
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 130%;
                        color: $text-black-100 !important;
                    }
                }

                &:hover {
                    & > a {
                        color: $main-purple-100 !important;
                    }
                }
            }
        }
    }

    &__logo {
        & img {
            width: 83px;
            height: 25px;
            margin-right: 51px;

            @include max-w-xs {
                width: 110px;
                height: 20px;
            }
        }

        & svg {
            @include max-w-xs {
                width: 110px;
                height: 20px;
            }
        }
    }

    &__links {
        @include max-w-lg {
            flex-direction: column;
        }
    }

    &__social {
        width: 128px;
        cursor: pointer;

        @include max-w-lg {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }

    &__toggler {
        @include min-w-sm {
            display: none;
        }

        z-index: 20;
    }
}

.backdrop {
    display: none;
    background: $black-60;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 124;

    &.is-active {
        display: block;
    }
}

.white {
    & > svg {
        & path {
            fill: $white-100 !important;
        }
    }
}

.black {
    & > svg {
        & path {
            fill: #231f20 !important;
        }
    }
}
