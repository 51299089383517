.fw {
    &-400 {
        font-weight: 400;
    }

    &-600 {
        font-weight: 600;
    }

    &-700 {
        font-weight: 700;
    }
}
