.our-solution-menu {
    background: #f1f2fa;
    border-radius: 12px;
    padding: 4px;

    @include max-w-xs {
        // max-width: 335px;
        display: flex;
        justify-content: space-between;
        width: 100%;

        & > label {
            font-weight: 600;
            font-size: 12px;
            line-height: 130%;
        }
    }

    // @include max-w-xs {
    //     max-width: 335px;
    // }

    &.faq {
        @include max-w-sm {
            max-width: none;
        }
    }
}

.technology {
    & > :nth-child(1) {
        & .technology__logo {
            & > img {
                width: 124px;
                height: 31px;
            }
        }
    }

    & > :nth-child(2) {
        & .technology__logo {
            & > img {
                width: 93px;
                height: 30px;
            }
        }
    }

    & > :nth-child(3) {
        & .technology__logo {
            & > img {
                width: 80px;
                height: 31px;
            }
        }
    }
}

.support-areas {
    & .container {
        @include max-w-sm {
            padding: 0;
        }
    }

    & .card-box {
        min-height: 250px;

        &__inner {
            min-height: 190px;
        }
    }
}

.our-solution {
    &__supports {
        & > :nth-child(1) {
            & .card-box {
                background: rgba(37, 205, 175, 0.1);

                & > .card-box__inner {
                    & > img {
                        width: 189px;
                        object-fit: contain;
                    }

                    &.operation-box {
                        padding: 20px 6px 9px 0;

                        & > img {
                            width: 210px;
                        }
                    }
                }
            }
        }

        & > :nth-child(2) {
            & .card-box {
                background: rgba(125, 63, 255, 0.1);

                & > .card-box__inner {
                    & > img {
                        width: 175px;
                        object-fit: contain;
                    }

                    &.operation-box {
                        & > img {
                            width: 185px;
                        }
                    }
                }
            }
        }

        & > :nth-child(3) {
            & .card-box {
                background: rgba(77, 84, 255, 0.1);

                & > .card-box__inner {
                    & > img {
                        width: 210px;
                        object-fit: contain;
                    }

                    &.operation-box {
                        & > img {
                            width: 160px;
                        }
                    }
                }
            }
        }
    }

    &__talent {
        width: 100%;
        max-width: 790px;

        & > :nth-child(1) {
            & .card-box__inner {
                padding: 15px 0;

                & > img {
                    width: 231px;
                    object-fit: contain;
                }
            }

            & .card-box {
                background: rgba(37, 205, 175, 0.1);
                height: 276px;
            }
        }

        & > :nth-child(2) {
            & .card-box__inner {
                padding: 10px 0;

                & > img {
                    width: 239px;
                    object-fit: contain;
                }
            }

            & .card-box {
                background: rgba(125, 63, 255, 0.1);
                height: 276px;
            }
        }

        & > :nth-child(3) {
            & .card-box__inner {
                padding: 20px 0;

                & > img {
                    width: 258px;
                    object-fit: contain;
                }
            }

            & .card-box {
                background: rgba(77, 84, 255, 0.1);
                height: 276px;
            }
        }

        &.social-items {
            & > :nth-child(1) {
                & .card-box__inner {
                    & > img {
                        width: 153px;
                    }
                }

                & .card-box {
                    height: 250px;
                }
            }

            & > :nth-child(2) {
                & .card-box__inner {
                    & > img {
                        width: 175px;
                    }
                }

                & .card-box {
                    height: 250px;
                }
            }

            & > :nth-child(3) {
                & .card-box__inner {
                    & > img {
                        width: 150px;
                    }
                }

                & .card-box {
                    height: 250px;
                }
            }
        }
    }

    &__box {
        column-gap: 32px;

        @include max-w-sm {
            max-width: 335px;
            flex-direction: column-reverse;
        }
    }

    &__section-title {
        padding: 10px 20px;
        background: #f1f2fa;
        border-radius: 8px;
        text-align: center;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #636e95;
        font-weight: 600;
        font-size: 12px;
        line-height: 100%;
    }
}

#one-panel {
    & .our-solution__talent {
        & > :nth-child(1) {
            & .card-box__inner {
                padding: 15px 0;

                & > img {
                    width: 231px;
                }
            }
        }
    }
}

#two-panel {
    & .our-solution__talent {
        & > :nth-child(1) {
            & .card-box__inner {
                padding: 20px 0;

                & > img {
                    width: 231px;
                }
            }
        }

        & > :nth-child(2) {
            & .card-box__inner {
                padding: 20px 5px 10px 0;

                & > img {
                    width: 250px;
                }
            }
        }

        & > :nth-child(3) {
            & .card-box__inner {
                padding: 25px 0 5px 15px;

                & > img {
                    width: 310px;
                }
            }
        }
    }
}

#three-panel {
    & .our-solution__talent {
        & .card-box {
            height: 250px;
        }

        & > :nth-child(1) {
            & .card-box__inner {
                padding: 20px 0;

                & > img {
                    width: 165px;
                }
            }
        }

        & > :nth-child(2) {
            & .card-box__inner {
                padding: 20px 0 10px;

                & > img {
                    width: 216px;
                }
            }
        }

        & > :nth-child(3) {
            & .card-box__inner {
                padding: 20px 0 10px;

                & > img {
                    width: 184px;
                }
            }
        }
    }
}
