.slider-wrap {
    overflow: hidden;
    margin-right: -20px;
    position: relative;

    // @media (max-width: 1101px) {
    //     margin-right: -20px;
    // }

    & .swiper-container {
        padding-right: 22px;
        padding-left: 22px;

        // @media (max-width: 1101px) {
        //     padding-right: 22px;
        //     padding-left: 0;
        // }
    }
}

.our-customers {
    .slider-wrap {
        margin-right: -45px;

        @media (max-width: 1101px) {
            margin-right: -20px;
        }

        & .swiper-container {
            padding-right: 32px;
            padding-left: 32px;

            @media (max-width: 1101px) {
                padding-right: 22px;
                padding-left: 0;
            }
        }
    }
}

.swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $black-5;
    cursor: pointer;

    &:not(:first-child) {
        margin-left: 10px;
    }
}

.swiper-pagination-bullet-active {
    width: 24px;
    background: #8015e8;
    border-radius: 10px;
    transition: 0.3s width;
}

.swiper-pagination {
    margin-top: 40px;
    display: flex;
    justify-content: center;
}

.swiper-arrows {
    display: flex;
    position: absolute;
    justify-content: space-between;
    width: 98%;
    left: 10px;
    z-index: 200;
    bottom: 50%;

    @media (max-width: 1101px) {
        display: none;
    }
}

.swiper-button-prev {
    width: 44px;
    height: 44px;
    background-color: $white-100;
    border-radius: 50%;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.05);
    cursor: pointer;
    background-image: url("/images/svg/chevron_right_violet.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px 24px;
    transform: scaleX(-1);

    &:hover {
        background-image: url("/images/svg/chevron_right_violet.svg");
        background-size: 24px 24px;
        background-color: #f2e7fd;
        box-shadow: 0 5px 10px rgba(125, 20, 236, 0.05);
        transform: scaleX(-1);
    }

    &.swiper-button-disabled {
        background-image: url("/images/svg/slider-arrow.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 7px 11px;
        transform: scaleX(1);

        &:hover {
            background-color: #ffffff;
            cursor: default;
        }
    }
}

.swiper-button-next {
    width: 44px;
    height: 44px;
    background-color: $white-100;
    border-radius: 50%;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.05);
    cursor: pointer;
    background-image: url("/images/svg/chevron_right_violet.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px 24px;
    transform: scaleX(1);

    &:hover {
        background-image: url("/images/svg/chevron_right_violet.svg");
        background-size: 24px 24px;
        background-color: #f2e7fd;
        box-shadow: 0 5px 10px rgba(125, 20, 236, 0.05);
        transform: scaleX(1);
    }

    &.swiper-button-disabled {
        background-image: url("/images/svg/slider-arrow.svg");
        transform: scaleX(-1);
        background-size: 7px 11px;

        &:hover {
            background-color: #ffffff;
            cursor: default;
        }
    }
}
