.our-customers {
    &__container {
        width: 100%;
        padding: 0 20px;
        max-width: 1200px;
        margin: 0 auto;

        // @media (max-width: 1500px) {
        //     width: 100%;
        //     max-width: 1257px;
        //     align-items: center;
        //     margin-right: 0;
        // }

        // @include max-w-xl {
        //     width: 100%;
        //     max-width: 1200px;
        //     align-items: center;
        //     margin-right: 0;
        // }
    }

    &__text {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 0.2fr 0.75fr 0.2fr;
    }

    &__main-image {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-row-end: 4;
        grid-column-end: 2;

        & > img {
            border-radius: 15px;
            width: 276px;
            object-fit: contain;
        }

        @include max-w-md {
            width: 202px;
            height: 202px;
            grid-row-start: 1;
            grid-column-start: 1;
            grid-row-end: 3;
            grid-column-end: 2;
        }

        @include max-w-sm {
            width: 92px;
            height: 92px;
        }
    }

    &__logo-image {
        grid-row-start: 1;
        grid-column-start: 2;
        grid-row-end: 2;
        grid-column-end: 3;

        @include max-w-md {
            grid-row-start: 1;
            grid-column-start: 2;
            grid-row-end: 2;
            grid-column-end: 3;
            margin-top: 5px;
        }

        @include max-w-sm {
            width: 84px;
            height: 20px;
        }
    }

    &__slide-text {
        grid-row-start: 2;
        grid-column-start: 2;
        grid-row-end: 3;
        grid-column-end: 3;

        @include max-w-md {
            grid-row-start: 3;
            grid-column-start: 1;
            grid-row-end: 4;
            grid-column-end: 3;
            margin-top: 20px;
        }
    }

    &__bottom {
        grid-row-start: 3;
        grid-column-start: 2;
        grid-row-end: 4;
        grid-column-end: 3;

        @include max-w-md {
            grid-row-start: 2;
            grid-column-start: 2;
            grid-row-end: 3;
            grid-column-end: 3;
            margin-top: 13px;
        }

        @include max-w-sm {
            & > h5 {
                font-size: 16px;
                line-height: 160%;
            }

            & > div {
                font-size: 16px;
            }
        }
    }

    &__slider {
        max-width: 994px;

        & .swiper-wrapper {
            & > :not(:last-child) {
                margin-right: 40px;
            }

            & > :nth-child(1) {
                & .our-customers {
                    &__logo-image {
                        & > img {
                            width: 126px;
                            object-fit: contain;

                            @include max-w-sm {
                                width: 84px;
                            }
                        }
                    }
                }
            }

            & > :nth-child(2) {
                & .our-customers {
                    &__logo-image {
                        & > img {
                            width: 108px;
                            object-fit: contain;

                            @include max-w-sm {
                                width: 72px;
                            }
                        }
                    }
                }
            }

            & > :nth-child(3) {
                & .our-customers {
                    &__logo-image {
                        & > img {
                            width: 100px;
                            object-fit: contain;

                            @include max-w-sm {
                                width: 67px;
                                object-fit: contain;
                            }
                        }
                    }
                }
            }
        }
    }

    &__slide {
        position: relative;
        padding: 60px;
        border: 1px solid $black-5;
        border-radius: 20px;
        // width: 994px;
        width: 100%;
        min-width: 100%;
        max-width: none;
        height: 396px;
        display: grid;
        // grid-template-columns: 0.34fr 0.66fr;
        // grid-template-rows: 0.32fr 1fr 0.2fr;
        grid-template-columns: max-content auto;
        grid-template-rows: 0.32fr 1fr 0.2fr;
        grid-column-gap: 60px;
        grid-row-gap: 0;

        @include max-w-1096 {
            width: 100%;
        }

        @include max-w-md {
            padding: 25px;
            height: max-content;
            grid-template-columns: 0.2fr 0.8fr;
        }

        @include max-w-sm {
            grid-template-columns: 0.15fr 1fr;
            grid-template-rows: 0.1fr 0.4fr max-content;
            grid-column-gap: 20px;
        }

        @include max-w-2xs {
            grid-template-columns: 0.4fr 1fr;
            grid-template-rows: 0.1fr 0.4fr max-content;
            grid-column-gap: 20px;
        }

        & > img {
            width: 276px;
            height: 276px;
        }
    }

    & .swiper-wrapper {
        max-width: none;
        width: 100%;
        display: flex;
        justify-content: space-between;

        @include max-w-1096 {
            width: 100%;
        }
    }
}
