.initiatives {
    &__inner {
        max-width: 994px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        column-gap: 32px;

        & > :nth-child(1) {
            & .card-box__inner {
                background: rgba(37, 205, 175, 0.1);

                & > img {
                    width: 190px;
                    height: 170px;
                }
            }
        }

        & > :nth-child(2) {
            @include max-w-1096 {
                margin-top: 30px;
            }

            & .card-box__inner {
                background: rgba(125, 63, 255, 0.1);

                & > img {
                    width: 151px;
                    height: 150px;
                }
            }
        }

        & > :nth-child(3) {
            @include max-w-1096 {
                margin-top: 30px;
            }

            & .card-box__inner {
                background: rgba(77, 84, 255, 0.1);

                & > img {
                    width: 122px;
                    height: 130px;
                }
            }
        }

        @include max-w-1096 {
            flex-direction: column;
        }
    }

    &.card-box {
        width: 310px;
        height: 250px;
        background: #ffffff;

        @include max-w-xs {
            width: 100%;
        }
    }
}

.microloans {
    & > :not(:first-child) {
        margin-top: 32px;

        @include max-w-md {
            margin-top: 30px;
        }
    }

    & .card-box {
        justify-content: flex-start;
    }

    &__icon {
        padding: 7.5px;
        background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
        border-radius: 10px;

        & > img {
            width: 45px;
        }
    }
}

.resource {
    &__inner {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 32px;
        grid-row-gap: 60px;

        & > :nth-child(1) {
            & .resource__image {
                background: rgba(37, 205, 175, 0.2);
                // padding: 6px 1px;
                padding: 18px 15px;

                & > img {
                    width: 90px;
                    object-fit: contain;
                }

                @include max-w-xs {
                    padding: 6px 0;

                    & > img {
                        width: 60px;
                        height: 56px;
                    }
                }
            }
        }

        & > :nth-child(2) {
            & .resource__image {
                background: rgba(125, 63, 255, 0.1);
                padding: 6px 6px 0 6px;

                & > img {
                    width: 100px;
                    object-fit: contain;
                }

                @include max-w-xs {
                    padding: 4px;

                    & > img {
                        width: 74px;
                    }
                }
            }
        }

        & > :nth-child(3) {
            & .resource__image {
                background: rgba(77, 84, 255, 0.1);
                padding: 8px 1px;

                & > img {
                    width: 113px;
                    height: 99px;
                    object-fit: contain;
                }

                @include max-w-xs {
                    padding: 6px 0;

                    & > img {
                        width: 65px;
                        height: 55px;
                    }
                }
            }
        }

        & > :nth-child(4) {
            & .resource__image {
                background: rgba(37, 205, 175, 0.1);
                padding: 12px 0;

                & > img {
                    width: 113px;
                    height: 89px;
                    object-fit: contain;
                }

                @include max-w-xs {
                    padding: 6px 0;

                    & > img {
                        width: 65px;
                        height: 55px;
                    }
                }
            }
        }

        @include max-w-sm {
            & .resource__image-box {
                min-width: 100px;
                min-height: 100px;
            }
        }

        @include max-w-1096 {
            display: flex;
            flex-direction: column;
            width: max-content;
            grid-row-gap: 60px;
        }

        @include max-w-sm {
            grid-row-gap: 40px;
            width: auto;
        }
    }

    &__image-box {
        background: #ffffff;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.03);
        border-radius: 20px;
        padding: 30px;
        max-width: 173px;
        max-height: 173px;

        @include max-w-xs {
            padding: 17px 18px;
            border-radius: 10px;
        }
    }

    &__image {
        // padding: 27px;
        // background: rgba(37, 205, 175, 0.2);
        border-radius: 20px;

        // & > img {
        //     width: 113px;
        //     height: 113px;
        // }

        &--bright-purple {
            background: rgba(125, 63, 255, 0.1);
        }

        &--bright-blue {
            background: rgba(77, 84, 255, 0.1);
        }

        &--turquoise {
            background: rgba(37, 205, 175, 0.1);
        }

        @include max-w-xs {
            padding: 14px;
            border-radius: 10px;

            & > img {
                width: 60px;
                height: 56px;
            }
        }
    }

    &__item {
        display: flex;
        column-gap: 30px;
        max-width: 481px;
        align-items: center;

        @include max-w-xs {
            column-gap: 20px;
        }
    }

    &__documents {
        & > p {
            font-weight: 600;
            font-size: 12px;
            line-height: 100%;
            color: #3f2d54;
            letter-spacing: 2px;
            text-transform: uppercase;
            margin-left: 5px;
        }
    }

    &__documents-icon {
        padding: 10px;
        background: $white-100;
        box-shadow: 0 5px 10px rgba(125, 20, 236, 0.05);
        border-radius: 6px;

        @include max-w-xs {
            & > img {
                width: 14px;
                height: 14px;
            }
        }
    }
}

.partnerships {
    padding: 30px;
    background: $white-100;
    border-radius: 20px;
    min-height: 278px;

    @include max-w-sm {
        padding: 25px;
    }

    &__menu {
        background: #f1f2fa;
        border-radius: 12px;
        padding: 4px;
        display: flex;

        & > label {
            width: 100%;
        }

        @include max-w-sm {
            & > label {
                font-weight: 600;
                font-size: 12px;
                line-height: 130%;
            }
        }

        @include max-w-xs {
            max-width: 335px;
        }
    }
}

.community-panels {
    & > :nth-child(1) {
        & > img {
            width: 104px;
            height: 30px;
        }
    }

    & > :nth-child(2) {
        & > img {
            width: 90px;
            height: 30px;
        }
    }

    & > :nth-child(3) {
        & > img {
            width: 115px;
            height: 30px;
        }
    }
}
