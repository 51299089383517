.post-menu {
    & > a {
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        color: #8d8d91;
        display: inline;
    }

    & > a:last-of-type {
        display: inline-block;
        max-width: 98%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }

    & > img {
        display: inline;
    }

    & > :first-child {
        color: $main-purple-100;
    }
}

.post {
    padding-top: 115px;

    @include max-w-xs {
        padding-top: 94px;
    }

    &-image {
        & > img {
            border-radius: 20px;
            height: 370px;
            object-fit: cover;
            width: 994px;

            @include max-w-xs {
                height: 150px;
            }
        }

        & > p {
            & iframe {
                height: 540px !important;
                max-height: 540px;
                width: 100% !important;
                border-radius: 15px;

                @include max-w-md {
                    height: 400px !important;
                }

                @include max-w-xs {
                    height: 200px !important;
                }
            }
        }
    }
}

.post-testimonials-wrap {
    margin-left: -20px;
}

// .post-testimonials-wrapper {
//     padding-left: 20px;

//     @include max-w-sm {
//         padding-left: 0;
//     }
// }

.post-container {
    max-width: 994px;
    width: 100%;
}

.text-container {
    max-width: 790px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 80px;

    & > p {
        & iframe {
            position: relative;
            border-radius: 15px;
            left: 50%;
            transform: translateX(-50%);
            height: 340px !important;
            max-height: 340px;
            width: 80% !important;

            @include max-w-xs {
                height: 200px !important;
                width: 100%;
            }
        }
    }

    @include max-w-xs {
        margin-top: 40px;
    }

    & ul {
        position: relative;
        margin-left: 11px;
        margin-top: 20px;

        & > li {
            font-weight: 400;
            font-size: 15px;
            line-height: 160%;
            color: #636e95;
            margin-left: 28px;

            &:not(:first-child) {
                margin-top: 10px;
            }

            &::before {
                content: "";
                display: block;
                width: 18px;
                height: 18px;
                transform: translateY(4px);
                left: 0;
                background: #f2e7fd;
                background-image: url("/images/svg/check-icon.svg");
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
                border-radius: 50%;
            }
        }
    }

    & > h2 {
        @include responsive-fz(28, 22);

        margin-top: 80px;
        line-height: 130%;
        font-weight: 600;
        color: $text-black-100;

        @include max-w-xs {
            margin-top: 40px;
        }
    }

    & > h3 {
        @include responsive-fz(20, 20);

        margin-top: 60px;
        line-height: 130%;
        font-weight: 600;

        @include max-w-xs {
            margin-top: 25px;
        }
    }

    @include min-w-xl {
        h2 {
            font-size: 28px;
        }

        h3 {
            font-size: 20px;
        }
    }

    & p {
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        color: #636e95;
        margin-top: 30px;

        @include max-w-xs {
            margin-top: 25px;
        }
    }

    & strong {
        font-weight: 600;
        font-size: 16px;
        line-height: 160%;
        color: #3f2d54;
    }

    & a {
        font-weight: 600;
        font-size: 16px;
        line-height: 160%;
        color: #7a12ef;

        & > strong {
            color: #7a12ef;
        }
    }
}

.article {
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.4) 100%);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.03);
    border-radius: 20px;
    width: 375px;

    &__image {
        & > img {
            width: 789px;
            height: 340px;
            object-fit: cover;
            border-radius: 20px;

            @include max-w-xs {
                height: 150px;
                border-radius: 15px;
            }
        }
    }
}

.recommended {
    &__text-container {
        padding: 30px;

        @include max-w-sm {
            padding: 20px;
        }
    }

    &__image {
        & > img {
            width: 379px;
            height: 300px;
            object-fit: cover;
            border-radius: 20px;

            @include max-w-xs {
                height: 150px;
                border-radius: 15px;
            }
        }
    }
}
