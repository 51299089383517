.check-list {
    & ul {
        position: relative;

        & > li {
            font-weight: 400;
            font-size: 15px;
            line-height: 160%;
            color: #636e95;
            margin-left: 23px;

            &:not(:first-child) {
                margin-top: 10px;
            }

            &::before {
                content: "";
                display: block;
                width: 18px;
                height: 18px;
                transform: translateY(4px);
                left: 0;
                background: #f2e7fd;
                background-image: url("/images/svg/check-icon.svg");
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
                border-radius: 50%;
            }
        }
    }
}

.check {
    position: relative;
    width: 22.5px;
    height: 22.5px;
    background: $milk-white-100;
    border-radius: 50%;

    &__checkmark {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 25%;
        transform: rotate(45deg) translate(-50%, -50%);
        height: 13.5px;
        width: 9.82px;
        border-bottom: 2px solid $dark-blue-100;
        border-right: 2px solid $dark-blue-100;
    }
}
