// .animation-path {
//     animation: donut3 3s;
// }
.graph-container {
    display: flex;
    justify-content: space-between;

    @include max-w-sm {
        display: block;
        position: relative;
        overflow-x: scroll;
        overflow-y: hidden;
        left: 0;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.graph-animation {
    @include max-w-sm {
        width: 1200px;
    }

    &.start-animate {
        & .graph-line-animation {
            stroke-dasharray: 1200;
            stroke-dashoffset: 1200;
            animation: dash 3s linear forwards;
        }

        & .graph-1-gradient {
            opacity: 0;
            animation: opacity 1s linear forwards;
            animation-delay: 0s;
        }

        & .graph-2-gradient {
            opacity: 0;
            animation: opacity 1s linear forwards;
            animation-delay: 1s;
        }

        & .graph-3-gradient {
            opacity: 0;
            animation: opacity 1s linear forwards;
            animation-delay: 2s;
        }

        & .graph-1-image {
            opacity: 0;
            animation: opacity 1s linear forwards;
            animation-delay: 0s;
        }

        & .graph-2-image {
            opacity: 0;
            animation: opacity 1s linear forwards;
            animation-delay: 0.5s;
        }

        & .graph-3-image {
            opacity: 0;
            animation: opacity 1s linear forwards;
            animation-delay: 1s;
        }

        & .graph-4-image {
            opacity: 0;
            animation: opacity 1s linear forwards;
            animation-delay: 2s;
        }

        & .graph-silver-line {
            stroke-dasharray: 1200;
            stroke-dashoffset: 1200;
            animation: dash 3.5s linear forwards;
        }

        & .graph-silver-text {
            opacity: 0;
            animation: opacity 0.5s linear forwards;
            animation-delay: 3s;
        }

        & .graph-text {
            opacity: 0;
            animation: opacity 0.5s linear forwards;
            animation-delay: 3s;
        }
    }
}

.rect-graph {
    &.start-animate {
        & .graph-rect {
            display: block;
            animation: gradient 1s forwards;
            transform-origin: 0 100%;
            transform: translate(140%, -80%) rotate(180deg);
        }
    }
}

.circle-graph-svg {
    &.start-animate {
        & .circle-graph {
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
            animation: dash1 2s linear forwards;
        }

        & .circle-graph-1 {
            stroke-dasharray: 100;
            stroke-dashoffset: 100;
            animation: dash 2s linear forwards;
        }
    }
}

@keyframes dash1 {
    from {
        stroke-dashoffset: 1000;
    }

    to {
        stroke-dashoffset: 0;
    }
}

@keyframes gradient {
    from {
        height: 0;
    }

    to {
        height: 65px;
    }
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes opacity {
    to {
        opacity: 1;
    }
}
