.btn {
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    outline: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;

    &--primary {
        background: $purple-gradient;
        border-radius: 10px;
        font-size: 0.889em;
        color: $white-100;
        // padding: 0.75em 1.25em;
        padding: 17px 32px;
        transition: opacity 0.3s linear;
        z-index: 1;

        &::after {
            content: "";
            margin-left: 10px;
            width: 24px;
            height: 24px;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("/images/svg/button_right-icon.svg");
        }

        &::before {
            position: absolute;
            content: "";
            inset: 0; /* same as { top: 0; right: 0; bottom: 0; left: 0; } */
            background: $hover-primary;
            z-index: -1;
            opacity: 0;
            border-radius: 10px;
            transition: opacity 0.15s linear;
        }

        &:hover::before {
            opacity: 1;
        }
    }

    &--arrow-pagination {
        background: transparent;
        border: none;
        width: 20px;
        height: 20px;
        padding: 0;
    }

    &--pagination {
        &.current {
            border-radius: 50%;
            background: $purple-gradient;
            color: $white-100;
        }

        cursor: pointer;
        background: transparent;
        border: none;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
        font-weight: 400;
        font-size: 15px;
        line-height: 160%;
    }

    &--link {
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        background: linear-gradient(268.01deg, #8015e8 -7.03%, #670aff 137.11%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: $main-purple-100;

        &::after {
            content: "";
            margin-left: 5px;
            width: 16px;
            height: 16px;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url("/images/svg/arrow-icon-right.svg");
        }
    }

    &--filter {
        font-weight: 600;
        font-size: 14px;
        line-height: 130%;
        color: $text-black-100;
        padding: 10px;

        &.active {
            background: $white-100;
            box-shadow: 0 5px 10px rgba(125, 20, 236, 0.05);
            border-radius: 12px;
            color: $main-purple-100;
        }
    }

    &--play-button {
        width: 54px;
        height: 54px;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(2px);
        border-radius: 50%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    &--more {
        display: none;
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        color: $white-100;

        &::after {
            content: "";
            margin-left: 5px;
            width: 24px;
            height: 24px;
            background-size: contain;
            background-repeat: no-repeat;
        }

        @media (max-width: 548px) {
            display: inline-flex;
        }

        &.show-less {
            margin-top: 50px;
            display: none;
            text-align: center;

            &::after {
                background-image: url("/images/svg/less-arrow.svg");
            }

            &--violet {
                background: linear-gradient(268.01deg, #8015e8 -7.03%, #670aff 137.11%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                color: #670aff;

                &::after {
                    background-image: url("/images/svg/less-arrow-violet.svg");
                }
            }
        }

        &.show-more {
            margin-top: 50px;

            &::after {
                background-image: url("/images/svg/more-arrow.svg");
            }

            & ~ div {
                & > :nth-child(n + 4) {
                    @media (max-width: 548px) {
                        display: none;
                    }
                }
            }

            &--violet {
                background: linear-gradient(268.01deg, #8015e8 -7.03%, #670aff 137.11%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                color: #670aff;

                &::after {
                    background-image: url("/images/svg/more-arrow-violet.svg");
                }
            }

            &:target {
                & ~ div {
                    & > :nth-child(n + 4) {
                        display: flex;
                    }
                }

                & ~ .show-less {
                    display: inline-flex;
                }

                display: none;
            }
        }
    }

    &--listen {
        background: #ffffff;
        box-shadow: 0 5px 10px rgba(125, 20, 236, 0.05);
        border-radius: 10px;
        font-size: 0.889em;
        color: $main-purple-100;
        padding: 12px 20px;
        transition: background 0.3s linear;

        &.video-mask {
            min-height: 0;
        }

        &::after {
            content: "";
            margin-left: 5px;
            width: 16px;
            height: 16px;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("/images/svg/mic-icon.svg");
        }

        &:hover {
            background: $hover-primary;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            color: $hover-primary;
        }
    }

    &--menu {
        border-radius: 12px;
        padding: 10px 16.67px;
        z-index: 5;
        width: 123.33px;
        font-weight: 600;
        font-size: 14px;
        line-height: 130%;
        color: $text-black-100;

        &.faq {
            width: 137px;

            @include max-w-xs {
                width: 100%;
            }

            @media (max-width: 387px) {
                padding: 10px 7px;
            }
        }

        @include max-w-xs {
            padding: 10px 9.5px;
            max-width: 100%;
        }

        @include max-w-2xs {
            padding: 10px 9px;
            // max-width: 80px;
        }
    }

    &.menu-active {
        background: #ffffff;
        color: #7a12ef;
        box-shadow: 0 5px 10px rgba(125, 20, 236, 0.05);
    }

    &--header {
        background: $purple-gradient;
        border-radius: 10px;
        font-size: 1em;
        color: $white-100;
        padding: 12px 20px;
        transition: opacity 0.3s linear;
        z-index: 1;
        height: 45px;
        width: 150px;

        &::after {
            content: "";
            margin-left: 5px;
            width: 16px;
            height: 16px;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("/images/svg/button_right-icon.svg");
        }

        &::before {
            position: absolute;
            content: "";
            inset: 0; /* same as { top: 0; right: 0; bottom: 0; left: 0; } */
            background: $hover-primary;
            z-index: -1;
            opacity: 0;
            border-radius: 10px;
            transition: opacity 0.15s linear;
        }

        &:hover::before {
            opacity: 1;
        }
    }

    &--arrow {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: rgba(20, 133, 255, 1);
    }

    &--connect {
        border: none;

        &:hover {
            & > svg {
                transform: rotate(40deg);

                & .arrow {
                    transform: rotate(-85deg);
                    transform-origin: 50% 50%;
                    // transition: transform $transition;
                }

                // transition: transform $transition;

                & .circle-bg {
                    fill-opacity: 1;
                    // transition: fill-opacity $transition;
                }
            }
        }
    }
}

.page-numbers {
    width: 100%;
    display: flex;
    align-items: center;

    &.prev,
    .next {
        background: transparent;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        padding: 0;
    }

    & > li {
        cursor: pointer;
        background: transparent;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
        font-weight: 400;
        font-size: 15px;
        line-height: 160%;
        margin-right: 25px;

        & .current {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background: $purple-gradient;
            color: $white-100;
        }
    }

    &.prev {
        margin-right: 5px;
    }

    &.next {
        margin-left: 5px;
    }
}

.collapse-pagination {
    display: inline-flex;

    @include max-w-sm {
        display: none;
    }
}

.collapse-pagination-mob {
    display: none;

    @include max-w-sm {
        display: inline-flex;
    }
}
