html {
    font-size: 18px;
}

html,
body {
    height: 100%;
    font-family: $font-primary;
    // background: $bg-dark;
}

body {
    font-size: 18px;
    font-weight: 400;
}

.wrap {
    // height: 100%;
    display: flex;
    flex-direction: column;

    &__inner {
        flex: 1 0 auto;
    }
}

// .reveal.active {
// }
