.our-story {
    &.primary-container {
        margin-top: 155px;
    }

    &__title {
        & > h3 {
            & > p {
                color: #636e95;

                & > strong {
                    color: $text-black-100;
                    font-weight: 600;
                }
            }
        }
    }
}

.achive-box {
    & > img {
        width: 60px;
        height: 60px;
    }
}

.problem-container {
    & > :nth-child(1) {
        & .card-box__inner {
            padding: 20px 0 10px;

            & > img {
                width: 191px;
                object-fit: contain;
            }
        }
    }

    & > :nth-child(2) {
        & .card-box__inner {
            padding: 20px 0 10px;

            & > img {
                width: 121px;
                object-fit: contain;
            }
        }
    }

    @include max-w-sm {
        & > :not(:first-child) {
            margin-top: 32px;
        }
    }
}

.leadership-founders {
    & > div {
        & > img {
            border-radius: 15px;
            width: 379px;
            height: 100%;
        }
    }
}

#our-story {
    & .our-solution__talent {
        & .card-box {
            height: 250px;
        }

        & > :nth-child(1) {
            & .card-box__inner {
                padding: 10px 0 0 15px;

                & > img {
                    width: 155px;
                    object-fit: contain;
                }
            }
        }

        & > :nth-child(2) {
            & .card-box__inner {
                padding: 20px 0 0 0;

                & > img {
                    width: 175px;
                    object-fit: contain;
                }
            }
        }

        & > :nth-child(3) {
            & .card-box__inner {
                padding: 20px 0;

                & > img {
                    width: 175px;
                    object-fit: contain;
                }
            }
        }
    }
}
