.static {
    position: static;
}

.fixed {
    position: fixed;
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.sticky {
    position: sticky;
}

.sm-max\: {
    @include max-w-sm {
        &absolute {
            position: absolute;
        }

        &relative {
            position: relative;
        }
    }
}
