.searchbox-wrap {
    position: relative;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.1);

    &--blog {
        background: #ffffff;

        & input {
            color: $text-black-100 !important;
        }
    }

    & input {
        padding: 15px;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        color: $white-100;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        border: none;
        width: 100%;
        height: 56px;
        outline: none;
        background: transparent;

        &::placeholder {
            font-weight: 400;
            font-size: 14px;
            line-height: 160%;
            color: $light-gray-100;
        }
    }

    & button {
        padding-right: 4px;
        // background-color: $white;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        // box-shadow: 5px 4px 6px rgba(0, 0, 0, 0.19);
        border: none;
        cursor: pointer;
        position: relative;
        background: transparent;

        @include max-w-sm {
            width: 40%;
        }

        & img {
            position: absolute;
            left: 80px;
            bottom: 20px;

            @include max-w-sm {
                display: none;
            }
        }

        & span {
            padding: 12px 20px;
            font-weight: 600;
            font-size: 16px;
            line-height: 130%;
            color: $white-100;
            background: linear-gradient(268.01deg, #8015e8 -7.03%, #670aff 137.11%);
            border-radius: 10px;
            border-radius: 8px;
            // box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.19);

            @include max-w-sm {
                padding: 9px 20px;
            }
        }
    }
}

.searchbox-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 24px;

    @include max-w-sm {
        left: 16px;
    }
}

.search-input {
    padding: 15px 15px 15px 49px;
    font-weight: 400;
    font-size: 15px;
    line-height: 160%;
    color: $text-black-100;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    border: none;
    width: 100%;
    height: 54px;
    outline: none;
    background: $white-100;

    &::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        color: $gray-blue-100;
    }
}

.search-icon {
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
}
