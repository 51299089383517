.last-section {
    background-image: url("/images/last-section-bg.png");
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    margin-bottom: -10px;

    @include max-w-sm {
        background-image: url("/images/last-section-bg-mob.png");
    }

    &--initiatives {
        background-image: url("/images/svg/initiatives-last-bg.svg");

        @include max-w-md {
            background-image: url("/images/initiatives-last-bg-mob.png");
        }
    }
}
